.navbar {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1000; /* Ensure the sidebar appears above other elements */
  transition: left 350ms ease-in-out; /* Apply smooth animation when showing/hiding the sidebar */
}

@media (min-width: 770px) {
  .nav-menu {
    width: 268px;
    transition: left 850ms ease-in-out; /* Adjust the transition duration as needed */
  }
}

@media (max-width: 769px) {
  .nav-menu {
    width: 200px;
  }
}

.nav-menu.active {
  left: 0;
  transition: left 350ms ease-in-out; /* Adjust the transition duration as needed */
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 4px 0px 4px 4px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}
