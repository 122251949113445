.mt-50{
    margin-top: 100px;
}
.full-screen-container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #f2f2f2; Set your desired background color */
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden; /* Optional: Hide horizontal scrollbar */
  }
/* .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-content {
    width: 500px;
  } */